export default {
  roomStatus: [
    {
      label: 'Semua',
      value: 'section_all',
      checked: false,
    },
    {
      label: 'Vacant',
      value: 'vacant',
      checked: false,
    },
    {
      label: 'Occupied (All)',
      value: 'category_all',
      checked: false,
    },
    {
      label: 'DBET',
      value: 'dbet',
      class: 'ml-24',
      checked: false,
    },
    {
      label: 'Booking',
      value: 'booking',
      class: 'ml-24',
      checked: false,
    },
  ],
  roomStatusOutOfOrder: [
    {
      label: 'Out Of Order (All)',
      value: 'category_all',
      checked: false,
    },
    {
      label: 'Renovasi',
      value: 'renovation',
      class: 'ml-24',
      checked: false,
    },
    {
      label: 'Undertable',
      value: 'undertable',
      class: 'ml-24',
      checked: false,
    },
    {
      label: 'Room preparation',
      value: 'room_preparation',
      class: 'ml-24',
      checked: false,
    },
    {
      label: 'Short Stay',
      value: 'short_stay',
      class: 'ml-24',
      checked: false,
    },
    {
      label: 'Lainnya',
      value: 'other',
      class: 'ml-24',
      checked: false,
    },
  ],
  bookingStatus: [
    {
      label: 'Semua',
      value: 'section_all',
      checked: false,
    },
    {
      label: 'Stay - Booking',
      value: 'booking',
      checked: false,
    },
    {
      label: 'Waiting for payment',
      value: 'waiting_for_payment',
      checked: false,
    },
    {
      label: 'Terminated',
      value: 'terminated',
      checked: false,
    },
    {
      label: 'Waiting for check-in',
      value: 'waiting_for_checkin',
      checked: false,
    },
    {
      label: 'Finished',
      value: 'finished',
      checked: false,
    },
    {
      label: 'Stay - DBET',
      value: 'dbet',
      checked: false,
    },
  ],
};
