<template>
  <bg-modal
    class="room-allotment-filter-modal"
    v-model="isShowModal"
    :body-scroll="false"
    desktop-size="lg"
    title="Filter Ketersediaan Kamar"
  >
    <bg-text size="body-1" class="mb-16"> Rentang waktu kontrak </bg-text>

    <bg-grid>
      <bg-grid-item :col="6" class="mb-16">
        <bg-datepicker
          id="date-picker-start"
          placeholder="Start Date"
          :data-testid="`roomAllotmentFilter-datePickerStart`"
          :value="startRange.date"
          :disabled-dates="startRange.disabledDates"
          @input="handleStartRangeInput"
        />
      </bg-grid-item>
      <bg-grid-item :col="6" class="mb-16">
        <bg-datepicker
          id="date-picker-end"
          placeholder="End Date"
          fixed-position="bottom-right"
          :data-testid="`roomAllotmentFilter-datePickerEnd`"
          :value="endRange.date"
          :disabled-dates="endRange.disabledDates"
          @input="handleEndRangeInput"
        />
        <bg-text size="label-2" :class="maxRangeDateTextClasses">
          Maksimal 3 bulan dari start date
        </bg-text>
      </bg-grid-item>
    </bg-grid>

    <bg-divider class="mb-16" />

    <!-- Room Status -->
    <bg-field label="Room Status" class="mb-16">
      <bg-grid>
        <bg-grid-item :col="6" class="mb-0">
          <div v-for="(item, index) in roomStatus" :key="index">
            <bg-checkbox
              :id="`room-status-${item.value}`"
              :label="item.label"
              :class="['mb-16', item.class]"
              v-model="item.checked"
              @input="
                handleCheckBoxMassUpdateClicked('roomStatus', item, index)
              "
            />
          </div>
        </bg-grid-item>

        <bg-grid-item :col="6" class="mb-0">
          <div v-for="(item, index) in roomStatusOutOfOrder" :key="index">
            <bg-checkbox
              :id="`room-status-ooo-${item.value}`"
              :label="item.label"
              :class="['mb-16', item.class]"
              v-model="item.checked"
              @input="
                handleCheckBoxMassUpdateClicked(
                  'roomStatusOutOfOrder',
                  item,
                  index
                )
              "
            />
          </div>
        </bg-grid-item>
      </bg-grid>
    </bg-field>

    <bg-divider class="mb-16" />

    <!-- Booking Status -->
    <bg-field label="Booking Status" class="mb-16">
      <bg-grid>
        <bg-grid-item
          :col="6"
          class="mb-0"
          v-for="(item, index) in bookingStatus"
          :key="index"
        >
          <bg-checkbox
            :id="`booking-status-${item.value}`"
            :label="item.label"
            class="mb-16"
            v-model="item.checked"
            @input="
              handleCheckBoxMassUpdateClicked('bookingStatus', item, index)
            "
          />
        </bg-grid-item>
      </bg-grid>
    </bg-field>

    <bg-divider class="mb-16" />

    <template #footer>
      <div class="flex justify-end">
        <bg-button size="lg" class="mr-16" @click="resetFilter">
          Reset
        </bg-button>
        <bg-button
          size="lg"
          variant="primary"
          @click="applyFilter"
          :disabled="isDisabledButton"
        >
          Terapkan
        </bg-button>
      </div>
    </template>
  </bg-modal>
</template>

<script>
import {
  BgModal,
  BgText,
  BgField,
  BgDivider,
  BgDatepicker,
  BgCheckbox,
  BgGrid,
  BgGridItem,
  BgButton,
} from 'bangul-vue';
import dayjs from 'dayjs';
import { dateFormatterToSend } from 'Utils/formatter';
import { useModalVisibility } from '@/composables/useModalVisibility';
import FilterCheckboxList from './data/FilterCheckboxList.js';
import { VALUE_MASS_UPDATE_CHECKBOX } from './constants/ValueCheckboxList';

const SECTION_GROUPING = {
  roomStatus: ['roomStatus', 'roomStatusOutOfOrder'],
  bookingStatus: ['bookingStatus'],
};

export default {
  name: 'RoomAllotmentFilterModal',
  components: {
    BgModal,
    BgText,
    BgField,
    BgDivider,
    BgDatepicker,
    BgCheckbox,
    BgGrid,
    BgGridItem,
    BgButton,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    activeFilters: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const { isShowModal } = useModalVisibility(props, emit);

    return {
      isShowModal,
    };
  },

  data() {
    return {
      startRange: {
        date: null,
        disabledDates: {},
      },
      endRange: {
        date: null,
        disabledDates: {},
      },
      ...FilterCheckboxList,
    };
  },

  watch: {
    isShowModal(value) {
      if (value) {
        this.setFilterActive(this.activeFilters);
      }
    },
    roomStatus: {
      deep: true,
      immediate: true,
      handler() {
        this.roomStatus[0].checked = this.isSectionAllCheckboxChecked(
          'roomStatus'
        );
        this.roomStatus[2].checked = this.isAllCheckboxChecked(
          this.roomStatus.slice(3)
        );
      },
    },
    roomStatusOutOfOrder: {
      deep: true,
      immediate: true,
      handler() {
        this.roomStatus[0].checked = this.isSectionAllCheckboxChecked(
          'roomStatus'
        );
        this.roomStatusOutOfOrder[0].checked = this.isAllCheckboxChecked(
          this.getFilterKeyUsed(this.roomStatusOutOfOrder)
        );
      },
    },
    bookingStatus: {
      deep: true,
      immediate: true,
      handler() {
        this.bookingStatus[0].checked = this.isAllCheckboxChecked(
          this.getFilterKeyUsed(this.bookingStatus)
        );
      },
    },
  },

  computed: {
    isMoreThanThreeMonths() {
      const startDate = dayjs(this.startRange.date);
      const endDate = dayjs(this.endRange.date);

      return endDate.diff(startDate, 'day') > 90;
    },
    maxRangeDateTextClasses() {
      const baseClass = 'room-allotment-filter-modal__text-maximal-range-date';

      return [
        baseClass,
        {
          [`${baseClass}--error`]: this.isMoreThanThreeMonths,
        },
      ];
    },
    filterPayload() {
      const filterItems = [
        {
          key: 'room_status',
          value: this.transformSelectedItems(this.roomStatus),
        },
        {
          key: 'room_status_ooo',
          value: this.transformSelectedItems(this.roomStatusOutOfOrder),
        },
        {
          key: 'booking_status',
          value: this.transformSelectedItems(this.bookingStatus),
        },
      ];

      return filterItems.filter(item => item.value);
    },
    isDisabledButton() {
      if (this.startRange.date && this.endRange.date) {
        return this.isMoreThanThreeMonths;
      }

      return this.filterPayload.length < 1;
    },
  },

  methods: {
    handleStartRangeInput(value) {
      this.startRange.date = value;
      this.endRange.disabledDates = {
        to: dayjs(value).add(30, 'day').toDate(),
      };
    },

    handleEndRangeInput(value) {
      this.endRange.date = value;
      this.startRange.disabledDates = {
        from: dayjs(value).subtract(30, 'day').toDate(),
      };
    },

    getFilterKeyUsed(checkboxData) {
      return checkboxData.filter(
        checkbox =>
          checkbox.value !== VALUE_MASS_UPDATE_CHECKBOX.sectionAll &&
          checkbox.value !== VALUE_MASS_UPDATE_CHECKBOX.categoryAll
      );
    },

    isAllCheckboxChecked(checkboxes) {
      return checkboxes.every(checkbox => checkbox.checked);
    },

    isSectionAllCheckboxChecked(dataName) {
      return SECTION_GROUPING[dataName].every(sectionName => {
        const data = this.getFilterKeyUsed(this[sectionName]);
        return this.isAllCheckboxChecked(data);
      });
    },

    handleCheckBoxMassUpdateClicked(dataName, item, index) {
      const isCheckingAllItemsInSection =
        item.value === VALUE_MASS_UPDATE_CHECKBOX.sectionAll;

      const isCheckingAllItemsInCategory =
        item.value === VALUE_MASS_UPDATE_CHECKBOX.categoryAll;

      if (isCheckingAllItemsInSection) {
        SECTION_GROUPING[dataName].forEach(sectionName => {
          this[sectionName].forEach(element => {
            element.checked = item.checked;
          });
        });

        return;
      }

      if (isCheckingAllItemsInCategory) {
        this[dataName].forEach((element, elIndex) => {
          if (elIndex > index) {
            element.checked = item.checked;
          }
        });
      }
    },

    setFilterCheckedStatus(data, filter) {
      return data.map(item => ({
        ...item,
        checked: !!filter?.includes(item.value),
      }));
    },

    setFilterActive(filters) {
      this.roomStatus = this.setFilterCheckedStatus(
        this.roomStatus,
        filters?.room_status
      );

      this.bookingStatus = this.setFilterCheckedStatus(
        this.bookingStatus,
        filters?.booking_status
      );

      this.roomStatusOutOfOrder = this.setFilterCheckedStatus(
        this.roomStatusOutOfOrder,
        filters?.room_status_ooo
      );

      const startDate = filters?.start_date
        ? dayjs(filters.start_date).toDate()
        : null;

      const endDate = filters?.end_date
        ? dayjs(filters.end_date).toDate()
        : null;

      this.handleStartRangeInput(startDate);
      this.handleEndRangeInput(endDate);
    },

    resetFilter() {
      this.startRange = {
        date: null,
        disabledDates: {},
      };

      this.endRange = {
        date: null,
        disabledDates: {},
      };

      this.$emit('on-reset-filter');

      this.isShowModal = false;
    },

    transformSelectedItems(items) {
      return items
        .filter(
          item =>
            item.checked &&
            item.value !== VALUE_MASS_UPDATE_CHECKBOX.categoryAll &&
            item.value !== VALUE_MASS_UPDATE_CHECKBOX.sectionAll
        )
        .map(item => item.value)
        .join(',');
    },

    transformFilterData(filters) {
      // eslint-disable-next-line consistent-return
      return filters.reduce((result, item) => {
        result[item.key] = item.value.split(',');
        return result;
      }, {});
    },

    applyFilter() {
      const payload = {
        start_date: dateFormatterToSend(this.startRange.date) || undefined,
        end_date: dateFormatterToSend(this.endRange.date) || undefined,
        filter: this.transformFilterData(this.filterPayload),
      };

      this.$emit('on-apply-filter', payload);
      this.isShowModal = false;
    },
  },
};
</script>

<style lang="scss" src="./RoomAllotmentFilterModal.scss"></style>
